import React from 'react'
import { Helmet } from 'react-helmet'
import Seo from '../components/util/Seo'

const ErrorPage: React.FC = ({ location }: any) => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div className="py-16">
            <div className="text-center">
              <h1 className="mt-2 text-4xl tracking-tight text-primary sm:text-5xl">You have encountered an error.</h1>
              <div className="mt-6">
                <a href="/" className="text-base font-medium text-primary hover:underline">
                  Go back home<span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default ErrorPage
